<template>
  <div id="addedit-main" class="alone-possess">
    <div class="addedit-title" v-if="otherIs == 0">{{ Title0 }}</div>
    <div class="addedit-title" v-else-if="otherIs == 1">{{ Title1 }}</div>
    <div class="addedit-title" v-else-if="otherIs == 2">{{ Title2 }}</div>
    <div class="addedit-bor"></div>
    <div class="addedit-cont">
      <n-form
        :model="model"
        ref="formRef"
        :rules="rules"
        label-placement="left"
        :label-width="120"
      >
        <n-form-item label="上级菜单 : " path="parent_name" v-if="otherIs != 1">
          <n-input
            placeholder="上级菜单"
            :disabled="true"
            v-model:value="model.parent_name"
          />
        </n-form-item>

        <n-form-item path="name" label="菜单名称 : " :show-require-mark="true">
          <n-input v-model:value="model.name" @keydown.enter.prevent />
        </n-form-item>

        <n-form-item path="route" label="路径 : " :show-require-mark="true">
          <n-input v-model:value="model.route" @keydown.enter.prevent />
        </n-form-item>

        <n-form-item path="route" label="图标 : ">
          <!-- 头像上传 -->
          <UPheadPortrait
            text="限上传PNG、JPG格式图片 建议50*50 大小不超过2M"
            v-if="isOne"
            @imagedata="imagedata"
            size="1"
            removeUrl="/backend/web/upload/delete"
            :imaData="model.file_url"
          />

          <!-- 头像上传 -->
          <UPheadPortrait
            text="限上传PNG、JPG格式图片 建议50*50 大小不超过2M"
            v-else
            @imagedata="imagedata"
            size="1"
            removeUrl="/backend/web/upload/delete"
            :imaData="model.file_url"
          />
        </n-form-item>

        <n-form-item label="排序 : " path="number">
          <n-input placeholder="排序" v-model:value="model.iorder" />
        </n-form-item>

        <n-form-item
          first
          path="remark"
          maxlength="50"
          label="备注 : "
          ref="rPasswordFormItemRef"
        >
          <n-input v-model:value="model.remark" type="text" />
        </n-form-item>
        <n-row :gutter="[0, 24]">
          <n-col :span="24">
            <div class="addedit-btns">
              <n-button type="tertiary" @click="xiangQing"> 返回 </n-button>
              <n-button
                color="#744DFE"
                @click="handleValidateButtonClick"
                round
              >
                确定</n-button
              >
            </div>
          </n-col>
        </n-row>
      </n-form>
      <PageLoading :loading="loading" />
      <pre>
      <!-- {{ JSON.stringify(model, 0, 2) }} -->
      </pre>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useMessage, useDialog } from "naive-ui";
import { resStatusEnum } from "@/enumerators/http.js";
import {
  GETAssignmentDelete,
  AssignmentUpdate,
  AssignmentCreate,
  SystemPowerRoles,
} from "@/api/admin-home.js"; // 详情 编辑 新增 角色列表
import UPheadPortrait from "@/components/UPheadPortrait/index.vue";
import { useRoute } from "vue-router";
import {
  GettMenuDetail,
  PostMenuUpdate,
  PostDeleteIcon,
  PostMenuCreate,
} from "@/api/admin-menus.js"; // 编辑详情 编辑 删除图标 添加菜单
import PageLoading from "@/components/PageLoading/index.vue";
import { toRaw } from "@vue/reactivity";

const { SUCCESS } = resStatusEnum;
const loading = ref(false);

export default defineComponent({
  // 挂载组件
  components: {
    UPheadPortrait,
    PageLoading,
  },
  setup(props, context) {
    const dialog = useDialog();
    const router = useRoute();
    let otherId = router.query.id;
    let otherIs = Number(router.query.is);
    const formRef = ref(null);
    const rPasswordFormItemRef = ref(null);
    const message = useMessage();
    const isOne = ref(false);

    const model = ref({
      parent_id: null, // 上级菜单ID
      parent_name: null, // 上级菜单名称
      name: null, // 子菜单名称
      route: null, // 路径
      remark: null, // 备注
      id: null, // 编辑时菜单ID
      file_url: [], // 图标url
      iorder: null, // 排序
      file_name: null, // 菜单图表文件名
    });

    // 数据初始化
    function InitData() {
      loading.value = true;
      // 菜单详情
      GettMenuDetail({ id: otherId })
        .then((res) => {
          if (res.code === SUCCESS) {
            loading.value = false;
            let data = {};
            // 编辑时1  添加子菜单0  新增2
            if (otherIs == 0) {
              model.value.id = res.data.id; // 菜单ID
              model.value.parent_name = res.data.menu_name; // 上级菜单名称
              model.value.parent_id = res.data.parent_id; // 上级菜单ID
            } else if (otherIs == 1) {
              model.value.id = res.data.id; // 菜单ID
              model.value.name = res.data.menu_name; // 菜单名称
              model.value.route = res.data.menu_url; // 菜单路径
              model.value.remark = res.data.remark; // 备注
              if (res.data.file_url != "") {
                isOne.value = true;
                let data = toRaw([
                  {
                    name: "cc.png",
                    url: res.data.file_url,
                    status: "finished",
                  },
                ]);
                model.value.file_url = data; // 图标URL
              } else {
                isOne.value = false;
                model.value.file_url = [];
              }
              // model.value.file_url = [{name:'cc.png',url:res.data.file_url,status: 'finished'}] // 图标URL
              model.value.iorder = res.data.iorder; // 排序
            } else if (otherIs == 2) {
            }
          }
        })
        .catch((reject) => {});
    }

    // 是否新增
    if (otherIs != 2) {
      InitData();
    }

    // 父子组件传值
    const imagedata = (params) => {
      for (var i in params) {
        model.value.file_url = params[i].url;
        model.value.file_name = params[i].name;
      }
    };

    return {
      otherIs,
      formRef,
      rPasswordFormItemRef,
      model,
      loading,
      Title0: "添加子菜单",
      Title1: "编辑菜单",
      Title2: "新增菜单",
      imagedata,
      isOne,
      rules: {
        name: [
          {
            required: false,
            validator(rule, value) {
              if (!value) {
              }
              return true;
            },
            trigger: ["input", "blur"],
          },
        ],
      },

      // 提交事件
      handleValidateButtonClick(e) {
        formRef.value.validate((errors) => {
          if (model.value.name != "" && model.value.route != "") {
            loading.value = true;
            let data = {
              "AdminMenu[parent_id]": model.value.parent_id, // 上级ID
              "AdminMenu[menu_name]": model.value.name, // 菜单名称
              "AdminMenu[menu_url]": model.value.route, // 菜单路径
              "AdminMenu[file_url]": model.value.file_url, // 菜单图标地址
              "AdminMenu[file_name]": model.value.file_name, // 菜单图标名称
              "AdminMenu[iorder]": model.value.iorder, // 排序
              "AdminMenu[id]": model.value.id, // 编辑时菜单ID
              "AdminMenu[remark]": model.value.remark, // 备注
            };
            // 编辑时1  添加子菜单0  新增2
            if (otherIs == 1) {
              PostMenuUpdate(data)
                .then((res) => {
                  if (res.code === SUCCESS) {
                    loading.value = false;
                    setTimeout(function () {
                      window.location.href = "/system-power/menus/list";
                    }, 1000);
                  } else {
                    loading.value = false;
                  }
                })
                .catch((err) => {});
            } else {
              PostMenuCreate(data)
                .then((res) => {
                  if (res.code === SUCCESS) {
                    loading.value = false;
                    message.success(res.msg);
                    dialog.warning({
                      title: "提示",
                      content: `新增菜单后,需要去给角色配置权限,方可在页面展示`,
                      positiveText: "确定",
                      onPositiveClick: () => {
                        window.location.href = "/system-power/roles/list";
                      },
                      onNegativeClick: () => {
                        // window.location.href = "/system-power/menus";
                      },
                    });

                  } else {
                    loading.value = false;
                  }
                })
                .catch((err) => {});
            }
          } else {
            console.log(errors);
            message.error("验证失败");
          }
        });
      },
      xiangQing() {
        window.location.href = "/system-power/menus/list";
      },
    };
  },
});
</script>

<style scoped>
#addedit-main {
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
}
#addedit-main .addedit-title {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}
.addedit-bor {
  height: 1px;
  border-bottom: 1px solid #d7d7d7;
  position: absolute;
  left: 0;
  right: 0;
  top: 65px;
}
.n-form-item-feedback-wrapper {
  padding-left: 120px;
}
.addedit-cont {
  width: 400px;
  padding-top: 45px;
}
.addedit-btns {
  display: flex;
  justify-content: flex-end;
  padding-top: 60px;
}
.addedit-btns button {
  border-radius: 2px !important;
  width: 120px;
}
.addedit-btns .n-button--tertiary-type.n-button--medium-type {
  border: 1px solid #ccc;
  margin-right: 30px;
}
.n-button__content a {
  color: #000;
  text-decoration: none;
}
</style>